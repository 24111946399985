import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import BrandLogo from './BrandLogo';
import QuickContact from './QuickContact';
import { Box, Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';

const NavBar = () => {
    const location = useLocation();
    const [showContact, setShowContact] = useState(true);

    const QuickMenu = loadable(() => import('./QuickMenu'));

    useEffect(() => {
        location.pathname === '/' ? setShowContact(true) : setShowContact(false);
    }, [location]);
    return (
        <Box component={'nav'} bgcolor={'white'}>
            {showContact && <QuickContact />}
            <BrandLogo />
            <Divider variant={'middle'} sx={{ display: { xs: 'none', lg: 'block' } }} />
            <QuickMenu />
        </Box>
    );
};

export default NavBar;
