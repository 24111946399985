import React from 'react';
import loading from '../../assets/animations/loading.json';
import { useLottie } from 'lottie-react';
import { Box } from '@mui/material';

const View = ({ loop, height = 300 }) => {
    const animationOptions = {
        loop: loop || false,
        autoplay: true,
        animationData: loading
    };

    const { View } = useLottie(animationOptions, { height: height });

    return View;
};

export const Page = ({ loop, height }) => {
    return (
        <Box>
            <View loop={loop} height={height} />
        </Box>
    );
};
