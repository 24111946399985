import { createSlice } from '@reduxjs/toolkit';
import { getStorageValue, updateStorageValue } from '../Utils/localStorage';

const preferences = getStorageValue('preferences', {
    lang: 'en'
});

export const PreferencesSlice = createSlice({
    name: 'preferences',
    initialState: {
        value: preferences
    },
    reducers: {
        update: (state, value) => {
            state.value = { ...state.value, ...value.payload };
            updateStorageValue('preferences', state.value);
        }
    }
});

export const { update } = PreferencesSlice.actions;

export const selectPreferencesState = (state) => state.preferences.value;

export default PreferencesSlice.reducer;
