import React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AlarmOnOutlinedIcon from '@mui/icons-material/AlarmOnOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export default function QuickContact() {
    return (
        <Grid
            container
            spacing={3}
            alignItems={'center'}
            justifyContent={'space-between'}
            direction={'row'}
            bgcolor={'primary.main'}
            textAlign={'center'}
            p={0.5}
        >
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    color={'white'}
                    justifyContent={'center'}
                >
                    <LocalPhoneOutlinedIcon color={'inherit'} />
                    <Link
                        component={'a'}
                        fontWeight={'inherit'}
                        pr={1}
                        variant={'subtitle2'}
                        href={'tel:+255677145999 '}
                        underline={'none'}
                        color={'white'}
                    >
                        +255 677 145 999
                    </Link>
                    /
                    <Link
                        component={'a'}
                        fontWeight={'inherit'}
                        variant={'subtitle2'}
                        href={'tel:+255677096422'}
                        underline={'none'}
                        color={'white'}
                    >
                        +255 677 025 817
                    </Link>
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    color={'white'}
                    justifyContent={'center'}
                >
                    <AlarmOnOutlinedIcon color={'inherit'} />
                    <Typography variant={'subtitle2'} pl={1} fontWeight={'inherit'}>
                        Mon - Sat 08:00 - 17:00
                    </Typography>
                </Box>
            </Grid>
            <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={12}
                color={'white'}
                sx={{
                    display: { xs: 'none', lg: 'block' }
                }}
            >
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <MailOutlineIcon color={'inherit'} />
                    <Typography variant={'subtitle2'} pl={1} fontWeight={'inherit'}>
                        order@nyamnyam.co.tz
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
}
