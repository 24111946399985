import { createSlice } from '@reduxjs/toolkit';

export const menuDrawerSlice = createSlice({
    name: 'menuDrawer',
    initialState: {
        value: false
    },
    reducers: {
        toggle: (state) => {
            state.value = !state.value;
        },
        off: (state) => {
            state.value = false;
        }
    }
});

export const { toggle, off } = menuDrawerSlice.actions;

export const selectMenuDrawerState = (state) => state.menuDrawer.value;

export default menuDrawerSlice.reducer;
