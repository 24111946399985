import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';
import Cart from './Cart';
import { debounce, Grid, IconButton, Stack } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { selectMenuDrawerState, toggle } from '../../slices/menuDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { Language } from './Language';

const BrandLogo = () => {
    const [showBrand, setShowBrand] = useState(false);
    const menuOpen = useSelector(selectMenuDrawerState);
    const dispatch = useDispatch();

    const SearchBar = loadable(() => import('../Search/SearchBar'), {
        ssr: false
    });

    const MobileNav = loadable(() => import('./MobileNav'), {
        ssr: false
    });

    const didScrollPage = () => {
        const headerStickyOffset = 80;
        window.scrollY > headerStickyOffset ? setShowBrand(true) : setShowBrand(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', debounce(didScrollPage, 100));

        return () => {
            window.removeEventListener('keydown', didScrollPage);
        };
    }, []);

    return (
        <>
            <Grid
                container
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-evenly'}
                px={'auto'}
                maxWidth={showBrand ? 'xxl' : 'lg'}
                mx={'auto'}
                pt={1}
                sx={
                    showBrand
                        ? {
                              position: 'fixed',
                              top: '0',
                              bgcolor: 'white',
                              zIndex: 100,
                              transition: 'all 0.5s'
                          }
                        : {}
                }
            >
                <Grid
                    item
                    xs={2}
                    md={3}
                    sx={{
                        pl: { md: 4, lg: 5 }
                    }}
                >
                    <Stack direction={'row'} spacing={2} justifyContent={'flex-start'}>
                        <IconButton
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(toggle());
                            }}
                            sx={{
                                display: { xs: 'block', lg: 'none' }
                            }}
                        >
                            <MenuRoundedIcon />
                        </IconButton>
                        <Link to="/">
                            <img src="/nyamnyam.svg" width="60" height="60" alt="NyamNyam Logo" />
                        </Link>
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs
                    sx={{
                        display: { xs: 'none', md: 'block' }
                    }}
                >
                    <SearchBar />
                </Grid>
                <Grid item xs={6} md={5} lg={4}>
                    <Stack
                        direction={'row'}
                        spacing={2}
                        justifyContent={'flex-end'}
                        sx={{ mr: { xs: 1, md: 3 } }}
                    >
                        {/*<Button*/}
                        {/*    variant="outlined"*/}
                        {/*    href={"#"}*/}
                        {/*    sx={{*/}
                        {/*        display: {xs: "none", lg: "block"},*/}
                        {/*        '&:hover': {*/}
                        {/*            color: 'white',*/}
                        {/*            bgcolor: "primary.main"*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {t.sign_in}*/}
                        {/*</Button>*/}
                        <Cart />
                        <Language />
                    </Stack>
                </Grid>
            </Grid>
            <MobileNav open={menuOpen} />
        </>
    );
};

export default BrandLogo;
