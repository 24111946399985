import React from 'react';
import { Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { PageLoader } from '../loading';

const Main = () => {
    const Home = loadable(() => import('../../pages/Home'), {
        ssr: false,
        fallback: <PageLoader />
    });
    const Products = loadable(() => import('../../pages/Products'), {
        ssr: false,
        fallback: <PageLoader />
    });
    const Cart = loadable(() => import('./cart/Cart'), {
        ssr: false,
        fallback: <PageLoader />
    });
    const Checkout = loadable(() => import('./checkout/Checkout'), {
        ssr: false,
        fallback: <PageLoader />
    });
    const Shop = loadable(() => import('../../pages/Shops'), {
        ssr: false,
        fallback: <PageLoader />
    });
    const Stories = loadable(() => import('../../pages/Story'), {
        ssr: false,
        fallback: <PageLoader />
    });
    const Description = loadable(() => import('../../pages/Product'), {
        ssr: false,
        fallback: <PageLoader />
    });
    const Policy = loadable(() => import('../../pages/Policy'), {
        ssr: false,
        fallback: <PageLoader />
    });

    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/categories/:id" component={Products} />
            <Route exact path="/products" component={Products} />
            <Route exact path={'/product/:slug'} component={Description} />
            <Route exact path="/cart" component={Cart} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path={'/shops'} component={Shop} />
            <Route exact path={'/story'} component={Stories} />
            <Route exact path={'/policy'} component={Policy} />
        </Switch>
    );
};
export default Main;
