import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';
import http from '../../http-common';
import { styled } from '@mui/styles';
import useTranslations from '../../Hooks/useTranslations';
import { useSnackbar } from 'notistack';

const TextFieldBox = styled(TextField)(() => ({
    '& .MuiInputBase-input': {
        borderRadius: 8,
        backgroundColor: 'white',
        color: 'black',
        fontSize: 14
    }
}));

TextFieldBox.propTypes = {
    fullWidth: PropTypes.bool,
    size: PropTypes.string,
    onChange: PropTypes.func,
    variant: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
};

const Subscribe = () => {
    const { t } = useTranslations();
    const { enqueueSnackbar } = useSnackbar();
    const [input, setInput] = useState({ email: '', first_name: '', last_name: '' });
    const [loading, setLoading] = useState(false);

    const subscribeToMail = async () => {
        setLoading(true);
        let form = new FormData();
        Object.keys(input).forEach((key) => {
            form.append(key, input[key]);
        });

        await http
            .post('/api/subscribe', form)
            .then((res) => {
                setLoading(false);
                enqueueSnackbar(res.data.message, {
                    variant: 'success'
                });
                setInput({ email: '', first_name: '', last_name: '' });
            })
            .catch((err) => {
                if (err.response) {
                    enqueueSnackbar(err.response.data.message, {
                        variant: err.response?.data?.state ?? 'error'
                    });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Box pb={2}>
                <Typography variant={'subtitle2'}>{t.footer.paragraph2}</Typography>
            </Box>
            <Stack spacing={4}>
                <Grid
                    container
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    direction={'row'}
                    spacing={2}
                >
                    <Grid item lg={6} xs>
                        <TextFieldBox
                            hiddenLabel
                            variant="outlined"
                            size={'small'}
                            fullWidth
                            value={input.first_name}
                            onChange={(e) => setInput({ ...input, first_name: e.target.value })}
                            placeholder={t.footer.first_name}
                        />
                    </Grid>
                    <Grid item lg={6} xs>
                        <TextFieldBox
                            hiddenLabel
                            variant="outlined"
                            size={'small'}
                            fullWidth
                            value={input.last_name}
                            onChange={(e) => setInput({ ...input, last_name: e.target.value })}
                            placeholder={t.footer.last_name}
                        />
                    </Grid>
                </Grid>
                <TextFieldBox
                    hiddenLabel
                    variant="outlined"
                    size={'small'}
                    value={input.email}
                    onChange={(e) => setInput({ ...input, email: e.target.value })}
                    placeholder={t.footer.email}
                    fullWidth
                />
                <LoadingButton
                    loading={loading}
                    variant={'outlined'}
                    sx={{
                        color: 'white',
                        borderColor: 'white',
                        '&:hover': {
                            color: 'primary.700',
                            borderColor: 'primary.700',
                            bgcolor: 'white'
                        }
                    }}
                    onClick={async (e) => {
                        e.preventDefault();
                        await subscribeToMail();
                    }}
                >
                    {t.footer.subscribe}
                </LoadingButton>
            </Stack>
        </>
    );
};

export { Subscribe as SubscribeModule };
