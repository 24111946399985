import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { getStorageValue } from '../Utils/localStorage';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API,
    headers: {
        'Content-Type': 'application/json',
        locale: getStorageValue('lang', 'sw')
    },
    withCredentials: true
});

const axiosBaseQuery = () => async (requestOpts) => {
    try {
        const result = await axiosInstance({
            url: requestOpts.url ?? requestOpts,
            method: requestOpts.method ?? 'GET',
            data: requestOpts.body,
            ...requestOpts
        });
        return { data: result.data };
    } catch (axiosError) {
        let err = axiosError;
        return {
            error: {
                status: err.response?.status,
                data: err.response?.data || err.message
            }
        };
    }
};

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Cart', 'Cart Totals'],
    endpoints: (build) => ({
        getProducts: build.query({
            query: () => '/api/products'
        }),
        getProduct: build.query({
            query: (product) => `/api/product?slug=${encodeURI(product)}`
        }),
        getCategories: build.query({
            query: (locale = 'en') => `/api/categories?locale=${encodeURI(locale)}`
        }),
        getCategory: build.query({
            query: ({ categoryId, locale }) =>
                `/api/categories/${categoryId}?locale=${encodeURI(locale)}`
        }),
        getSlideshows: build.query({
            query: () => '/api/banners'
        }),
        getTrending: build.query({
            query: () => '/api/products/trending'
        }),
        addItemToCart: build.mutation({
            query: ({ item, session }) => ({
                url: '/api/cart',
                method: 'POST',
                headers: {
                    'session-id': session
                },
                body: item
            }),
            invalidatesTags: ['Cart', 'Cart Totals']
        }),
        getCartCount: build.query({
            query: (session) => ({
                url: '/api/cart',
                headers: {
                    'session-id': session
                }
            }),
            providesTags: ['Cart']
        }),
        getCartItems: build.query({
            query: (session) => ({
                url: '/api/cart/items',
                headers: {
                    'session-id': session
                }
            }),
            providesTags: (results = []) => [
                'Cart',
                'Cart Totals',
                ...results.map(({ id }) => ({ type: 'Cart', id }))
            ]
        }),
        getCartItem: build.query({
            query: ({ session, item }) => ({
                url: `/api/cart/${item}`,
                headers: {
                    'session-id': session
                }
            }),
            providesTags: (result, error, arg) => [{ type: 'Cart', id: arg.item }]
        }),
        getCartTotals: build.query({
            query: (session) => ({
                url: '/api/cart/total',
                headers: {
                    'session-id': session
                }
            }),
            providesTags: ['Cart Totals']
        }),
        alterCartItemCount: build.mutation({
            query: ({ item, data, session }) => ({
                url: `/api/cart/${item}/${encodeURI(data)}`,
                method: 'PATCH',
                headers: {
                    'session-id': session
                }
            }),
            invalidatesTags: (result, error, arg) => ['Cart Totals', { type: 'Cart', id: arg.item }]
        }),
        addTransportFeeCondition: build.mutation({
            query: ({ id, session }) => ({
                url: '/api/cart/condition/transport',
                method: 'POST',
                body: {
                    area: id
                },
                headers: {
                    'session-id': session
                }
            }),
            invalidatesTags: ['Cart Totals']
        }),
        clearTransportFeeCondition: build.mutation({
            query: ({ session }) => ({
                url: '/api/cart/condition/transport/clear',
                method: 'POST',
                headers: {
                    'session-id': session
                }
            }),
            invalidatesTags: ['Cart Totals']
        }),
        deleteCartItem: build.mutation({
            query: ({ item, session }) => ({
                url: `/api/cart/${item}`,
                method: 'DELETE',
                headers: {
                    'session-id': session
                }
            }),
            invalidatesTags: ['Cart', 'Cart Totals']
        }),
        clearCart: build.mutation({
            query: ({ session }) => ({
                url: '/api/cart',
                method: 'DELETE',
                headers: {
                    'session-id': session
                }
            }),
            invalidatesTags: ['Cart', 'Cart Totals']
        }),
        checkout: build.mutation({
            query: ({ session, billing, locale = 'sw' }) => ({
                url: '/api/checkout',
                method: 'POST',
                headers: {
                    'session-id': session,
                    language: locale
                },
                body: billing
            }),
            invalidatesTags: ['Cart', 'Cart Totals']
        })
    })
});

export const {
    useGetProductQuery,
    useGetCategoriesQuery,
    useGetCategoryQuery,
    useGetSlideshowsQuery,
    useGetTrendingQuery,
    useAddItemToCartMutation,
    useGetCartCountQuery,
    useGetCartItemsQuery,
    useClearCartMutation,
    useDeleteCartItemMutation,
    useAlterCartItemCountMutation,
    useGetCartTotalsQuery,
    useCheckoutMutation,
    useAddTransportFeeConditionMutation,
    useClearTransportFeeConditionMutation
} = apiSlice;
