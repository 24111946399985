import { getStorageValue } from '../Utils/localStorage';

export const defaultLang = getStorageValue('lang', 'sw');

export const supportedLangs = {
    en: { display: 'EN', standard: 'en-US', flag: 'fi-gb' },
    sw: { display: 'SW', standard: 'sw', flag: 'fi-tz' }
};

export const langUrl = '/lang/{lang}.json';
