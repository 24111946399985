import React from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useState } from 'react';
import { Badge, IconButton } from '@mui/material';
import CartList from './CartList';
import { useGetCartCountQuery } from '../../slices/api';
import { useSelector } from 'react-redux';

const Cart = () => {
    const token = useSelector((state) => state.session.id);
    const { data: cartCount = 0 } = useGetCartCountQuery(token?.id);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="cart"
                aria-controls={open ? 'list-cart-items' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Badge badgeContent={cartCount} color="primary" showZero component={'span'}>
                    <ShoppingCartOutlinedIcon />
                </Badge>
            </IconButton>
            <CartList id="cart-items" open={open} anchorEl={anchorEl} onClose={handleClose} />
        </>
    );
};

export default Cart;
