import React, { useEffect, useState } from 'react';
import Main from './components/main/Main';
import { useLocation } from 'react-router-dom';
import NavBar from './components/navbar/NavBar';
import Footer from './components/footer/Footer';
import './App.scss';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useCookies } from 'react-cookie';
import uuid from 'react-uuid';
import { useDispatch } from 'react-redux';
import { update } from './slices/session';
import FloatingButton from './components/WhatsappFloatingButton/FloatingButton.tsx';
import useTranslations from './Hooks/useTranslations';
import { Box } from '@mui/material';
import { PageLoader } from './components/loading';
import FacebookPixel from './Utils/FacebookPixel';
import { SnackbarProvider } from 'notistack';
import ModalProvider from 'mui-modal-provider';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ca242c',
            50: '#ffe5e7',
            100: '#f8bcc0',
            200: '#ee9296',
            300: '#e5676e',
            400: '#dc3c44',
            500: '#c3232a',
            600: '#981920',
            700: '#6d1017',
            800: '#44080b',
            900: '#1e0001'
        }
    },
    typography: {
        fontFamily: "'Comfortaa', cursive"
    }
});

function App() {
    let { pathname } = useLocation();
    const [showFooter, setShowFooter] = useState(true);
    const [cookies, setCookie] = useCookies(['nyamnyamsession']);
    const { init, status: i18nStatus } = useTranslations();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(update(cookies.nyamnyamsession));
    }, [cookies]);
    useEffect(() => {
        location.pathname === '/checkout' ? setShowFooter(false) : setShowFooter(true);
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);
    useEffect(() => {
        if (typeof cookies.nyamnyamsession === 'undefined') {
            let date = new Date();
            date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
            setCookie(
                'nyamnyamsession',
                { id: uuid() },
                {
                    path: '/',
                    expires: date
                }
            );
        }
        init();
    }, []);

    return i18nStatus === 'loading' ? (
        <Box height={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <PageLoader loop height={400} />
        </Box>
    ) : (
        <React.StrictMode>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <FacebookPixel />
                    <SnackbarProvider
                        autoHideDuration={3000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <ModalProvider>
                            <div className="App">
                                <NavBar />
                                <div className="main">
                                    <Main />
                                </div>
                                {showFooter && <Footer />}
                                <FloatingButton />
                            </div>
                        </ModalProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </React.StrictMode>
    );
}

export default App;
