import React from 'react';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import useTranslations from '../../Hooks/useTranslations';
import 'flag-icons/css/flag-icons.min.css';

export const Language = () => {
    const { lang, supportedLanguages, setLang } = useTranslations();

    return (
        <FormControl sx={{ m: 1, minWidth: 71 }} size="small">
            <Select
                id="language-selector"
                value={lang}
                label="Language"
                onChange={(e) => setLang(e.target.value)}
            >
                {Object.entries(supportedLanguages).map(([code, value]) => (
                    <MenuItem value={code} key={code}>
                        <Box component={'span'} className={`fi ${value.flag} fis`} sx={{ mr: 1 }} />
                        {value.display}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
