import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../slices/api';
import sessionSlice from '../slices/session';
import { menuDrawerSlice } from '../slices/menuDrawer';
import { BillingInformationSlice } from '../slices/billingInformation';
import { PreferencesSlice } from '../slices/preferences';
import i18nReducer from '../slices/i18n';

const store = configureStore({
    reducer: {
        session: sessionSlice.reducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
        menuDrawer: menuDrawerSlice.reducer,
        billingInformation: BillingInformationSlice.reducer,
        preferences: PreferencesSlice.reducer,
        i18n: i18nReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: process.env.NODE_ENV === 'development'
});

export default store;
