import React from 'react';
import loadable from '@loadable/component';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { SubscribeModule } from './subscribe';
import useTranslations from '../../Hooks/useTranslations';

const Footer = () => {
    const { t } = useTranslations();

    const Credits = loadable(() => import('./credits'), {
        ssr: false,
        resolveComponent: (components) => components.Credits
    });

    const MobileCredits = loadable(() => import('./mobileCredits'), {
        ssr: false,
        resolveComponent: (components) => components.MobileCredits
    });

    return (
        <footer>
            <Grid
                container
                direction={'row'}
                alignItems={'flex-start'}
                justifyContent={'space-between'}
                fontFamily={"'Comfortaa', cursive"}
                sx={{
                    bgcolor: 'primary.main',
                    color: 'white',
                    px: { xs: 6, md: 10, lg: 18 },
                    py: 4
                }}
            >
                <Grid
                    item
                    sm={6}
                    xs={12}
                    sx={{
                        pb: { xs: 2, md: 'inherit' }
                    }}
                >
                    <Typography variant={'subtitle1'} fontWeight={'bolder'}>
                        {t.footer.paragraph1}
                    </Typography>
                    <SubscribeModule />
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                    sx={{
                        textAlign: { xs: 'left', sm: 'right' }
                    }}
                >
                    <Typography variant={'subtitle1'} fontFamily={'inherit'} fontWeight={700}>
                        {t.footer.join}
                    </Typography>
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        color={'white'}
                        sx={{
                            justifyContent: { xs: 'flex-start', sm: 'flex-end' }
                        }}
                    >
                        <IconButton
                            color={'inherit'}
                            href="https://twitter.com/nyamnyam_tz"
                            component="a"
                            target="_blank"
                            rel="noopener"
                            sx={[
                                (theme) => ({
                                    '&:hover': {
                                        color: theme.palette.primary.main,
                                        backgroundColor: 'white'
                                    }
                                })
                            ]}
                        >
                            <TwitterIcon color={'inherit'} />
                        </IconButton>
                        <IconButton
                            color={'inherit'}
                            href="https://www.linkedin.com/in/nyamnyam-tz-6b211320a"
                            component="a"
                            target="_blank"
                            rel="noopener"
                            sx={[
                                (theme) => ({
                                    '&:hover': {
                                        color: theme.palette.primary.main,
                                        backgroundColor: 'white'
                                    }
                                })
                            ]}
                        >
                            <LinkedInIcon color={'inherit'} />
                        </IconButton>
                        <IconButton
                            color={'inherit'}
                            href="https://www.instagram.com/nyamnyam_tz"
                            component="a"
                            target="_blank"
                            rel="noopener"
                            sx={[
                                (theme) => ({
                                    '&:hover': {
                                        color: theme.palette.primary.main,
                                        backgroundColor: 'white'
                                    }
                                })
                            ]}
                        >
                            <InstagramIcon color={'inherit'} />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Credits />
            <MobileCredits />
        </footer>
    );
};

export default Footer;
