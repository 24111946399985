import { createSlice } from '@reduxjs/toolkit';

const initialState = { id: '' };

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        update: (state, action) => {
            state.id = action.payload;
        },
        clear: (state) => {
            state.id = '';
        }
    }
});

export const { update, clear } = sessionSlice.actions;
export default sessionSlice;
