import React from 'react';
import {
    Alert,
    Avatar,
    Button,
    Divider,
    Grid,
    ListItem,
    List,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Paper,
    Popover,
    Skeleton,
    Typography,
    IconButton,
    Snackbar
} from '@mui/material';
import {
    useClearCartMutation,
    useDeleteCartItemMutation,
    useGetCartItemsQuery
} from '../../slices/api';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import useTranslations from '../../Hooks/useTranslations';

const CartList = ({ id, open, anchorEl, onClose }) => {
    const { t, lang } = useTranslations();
    const token = useSelector((state) => state.session.id);
    const { data: items = [], isFetching } = useGetCartItemsQuery(token?.id);
    const [clearCart] = useClearCartMutation();
    const [deleteCartItem] = useDeleteCartItemMutation();
    const [toast, setToast] = useState(false);

    const clearCartAction = async () => {
        try {
            await clearCart({ session: token.id }).then(() => {
                onClose();
                setToast(true);
            });
        } catch (err) {
            console.log('Failed to clear Cart', err);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setToast(false);
    };

    const deleteCartItemAction = async (product) => {
        try {
            await deleteCartItem({ item: product, session: token.id });
        } catch (err) {
            console.log('Failed to delete item', err);
        }
    };

    let cartItems = (cart) => {
        return cart.length === 0 ? (
            <MenuItem>
                <ListItemText sx={{ color: 'primary.main' }}>{t.cart.empty}</ListItemText>
            </MenuItem>
        ) : (
            cart.map((item, i) => {
                return (
                    <ListItem
                        key={i}
                        secondaryAction={
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={(e) => {
                                    e.preventDefault();
                                    deleteCartItemAction(item.id);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        }
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={
                                    item.model['image_url'] ||
                                    (item?.model?.['master_product']?.['thumb_url'] ?? '')
                                }
                                alt={item.model['ext_title']?.[lang] ?? item.name}
                                variant={'square'}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {item.model['ext_title']?.[lang] ?? item.name}
                        </ListItemText>
                        <Typography variant="body2" color="text.secondary" ml={1}>
                            {item.quantity}{' '}
                            {item.model['units'] ||
                                (item.model?.['master_product']?.['units'] ?? 'PCS')}
                        </Typography>
                    </ListItem>
                );
            })
        );
    };

    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Paper sx={{ width: 420, maxWidth: '100%' }}>
                    <List>
                        {isFetching ? (
                            <Skeleton
                                variant="rectangular"
                                height={118}
                                animation={'wave'}
                                width={320}
                            />
                        ) : (
                            cartItems(items)
                        )}
                        <Divider />
                        {items.length > 0 && (
                            <Grid
                                container
                                justifyContent={'center'}
                                alignItems={'center'}
                                direction={'row'}
                                maxWidth={'lg'}
                                mx={'auto'}
                                textAlign={'center'}
                                pt={1}
                            >
                                <Grid item xs={6}>
                                    <Button
                                        variant={'text'}
                                        onClick={onClose}
                                        component={Link}
                                        to={'/cart'}
                                    >
                                        {t.cart.go}
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant={'text'} onClick={clearCartAction}>
                                        {t.cart.clear}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </List>
                </Paper>
            </Popover>
            <Snackbar
                open={toast}
                autoHideDuration={1000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity={'warning'}>Cart cleared.</Alert>
            </Snackbar>
        </>
    );
};

export default CartList;
