import { useDispatch, useSelector } from 'react-redux';
import { setLangAsync } from '../slices/i18n';

export default function useTranslations() {
    const dispatch = useDispatch();
    const t = useSelector((state) => state.i18n.translations);
    const setLang = (lang) => dispatch(setLangAsync(lang));
    const lang = useSelector((state) => state.i18n.lang);
    const supportedLanguages = useSelector((state) => state.i18n.supportedLanguages);
    const status = useSelector((state) => state.i18n.status);
    return {
        t,
        lang,
        setLang,
        init: setLang,
        supportedLanguages,
        status
    };
}
