import { createSlice } from '@reduxjs/toolkit';
import { getStorageValue, updateStorageValue } from '../Utils/localStorage';

const billing = getStorageValue('billing_information', {
    phone_number: '',
    name: '',
    email: '',
    area: '',
    street: '',
    house_no: '',
    city: 'DSM',
    payment_option: 'airtel-money',
    delivery_option: 'delivery',
    pickup_shop: ''
});

export const BillingInformationSlice = createSlice({
    name: 'billingInformation',
    initialState: {
        value: billing
    },
    reducers: {
        update: (state, value) => {
            state.value = { ...state.value, ...value.payload };
        },
        clear: (state) => {
            state.value = {
                phone_number: '',
                name: '',
                email: '',
                area: '',
                street: '',
                house_no: '',
                city: 'DSM',
                payment_option: 'airtel-money',
                delivery_option: 'delivery',
                pickup_shop: 'Frostan Mikocheni (HQ)'
            };
            updateStorageValue('billing_information', state.value);
        },
        store: (state) => {
            updateStorageValue('billing_information', state.value);
        }
    }
});

export const { update, clear, store } = BillingInformationSlice.actions;

export const selectBillingInformationState = (state) => state.billingInformation.value;

export default BillingInformationSlice.reducer;
