import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const FacebookPixel = () => {
    const location = useLocation();
    useEffect(() => {
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                if (process.env.REACT_APP_ENV === 'production') {
                    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
                    ReactPixel.pageView();
                }
            });
    }, [location]);

    return null;
};

export default FacebookPixel;
